// 专著
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ZLMC',
                label: '资料名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
};
export default selfData;